<template>
  <div class="grid gap-8">
    <div
      v-if="data?.competency_matrix"
      class="boxed-tabs nav nav-tabs justify-end text-gray-600 dark:text-gray-500 rounded-md"
      role="tablist"
    >
      <VTab
        :name="TABS.DATA"
        :selected-tab="currentTab"
        class="btn border-0 shadow-none py-1.5 px-10 end-tab bg-gray-200 dark:bg-dark-1"
        :label="$t('app.data')"
        @click="updateTab"
      />

      <VTab
        :name="TABS.MATRIX"
        :selected-tab="currentTab"
        class="btn border-0 shadow-none py-1.5 px-10 bg-gray-200 dark:bg-dark-1"
        :label="$t('app.competency_matrix')"
        @click="updateTab"
      />
    </div>

    <div v-if="currentTab === TABS.MATRIX">
      <CompetencyMatrix :matrix="data?.competency_matrix" />
    </div>

    <div v-if="currentTab === TABS.DATA">
      <ReportSummary
        v-if="Object.keys(data)?.length > 0"
        :summary="data?.report_summary"
        class="pb-10"
      />

      <slot />
    </div>
  </div>
</template>

<script>
// Composables
import useTabs from "@/composables/useTabs";
// Components
import CompetencyMatrix from "./CompetencyMatrix";
import ReportSummary from "./ReportSummary";
import VTab from "@/components/VTab";

export default {
  components: {
    CompetencyMatrix,
    ReportSummary,
    VTab
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    // Constants
    // eslint-disable-next-line
    const TABS = ["MATRIX", "DATA"].reduce((acc, cv) => ({ ...acc, [cv]: cv }), {});

    // Composables
    const { currentTab, updateTab } = useTabs(TABS.DATA);

    return {
      TABS,
      // useTabs
      currentTab,
      updateTab
    };
  }
};
</script>
