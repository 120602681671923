<template>
  <TableCard :rows="rows" :headers="headers">
    <template #row="{ item, index }">
      <td
        v-for="(header, headerIndex) in headers"
        :key="`value-${header.value}-${headerIndex}`"
        class="dark:border-dark-5"
        :class="[{ 'border-b': index !== rows.length - 1 }, header.cellClass]"
      >
        <component
          :is="isCompetency(header.value, item) ? 'VChip' : 'span'"
          :text="item[header.value]"
          :class="getStatusColor(item[header.value])"
        >
          {{ item[header.value] }}
        </component>
      </td>
    </template>
  </TableCard>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useStore } from "@/store";
// Composables
import useColor from "@/composables/useColor";
// Components
import TableCard from "@/components/tables/TableCard";
import VChip from "@/components/VChip";

export default {
  components: {
    TableCard,
    VChip
  },
  props: {
    matrix: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Composables
    const { getStatusColor } = useColor();

    // Computed
    const userLang = computed(() => store.getters["user/language"]);
    const learners = computed(() => props.matrix?.learners ?? {});
    // eslint-disable-next-line
    const competencies = computed(() => props.matrix?.required_competencies ?? {});

    const headers = computed(() => {
      const headerClass = "justify-center";
      const cellClass = "text-center";

      return [
        {
          text: t("app.learners", 2),
          value: "learner"
        },
        {
          text: t("app.acquired"),
          value: "percentage_acquired",
          headerClass,
          cellClass
        },
        ...Object.keys(competencies.value)?.map(x => ({
          text: competencies.value[x]?.name?.[userLang.value]?.name,
          value: x,
          headerClass,
          cellClass
        }))
      ];
    });

    const rows = computed(() => {
      return Object.keys(learners.value)?.map(x => {
        const item = learners.value[x];
        const competencies = item?.competencies ?? {};

        return {
          ...item,
          learner: `${item?.firstname} ${item?.lastname}`,
          percentage_acquired: `${item?.percentage_acquired}%`,
          // eslint-disable-next-line
          ...Object.keys(competencies)?.reduce((acc, cv) => ({ ...acc, [cv]: competencies[cv] }), {}) ?? {}
        };
      });
    });

    // Methods
    const isCompetency = (value, item) => {
      const isCompetencyStatus = !headers.value
        .slice(0, 2)
        .map(x => x.value)
        .includes(value);

      return isCompetencyStatus && !!item[value];
    };

    return {
      headers,
      rows,
      isCompetency,
      // useColor
      getStatusColor
    };
  }
};
</script>
