<template>
  <div class="grid grid-cols-2 gap-5">
    <VCard>
      <template #title>
        <div class="flex items-center gap-1">
          <div class="font-normal">{{ $t("app.hours_completed") }}:</div>

          <div>
            {{ displayedTime }}
          </div>
        </div>
      </template>
    </VCard>

    <VCard>
      <template #title>
        <div class="flex items-center gap-1">
          <div class="font-normal">{{ $t("app.credits_completed") }}:</div>

          <div>
            {{ summary?.credits_completed ?? 0 }}
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
// Components
import VCard from "@/components/VCard";

export default {
  components: {
    VCard
  },
  props: {
    summary: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Computed
    const displayedTime = computed(() => {
      const time = props.summary?.hours_completed ?? "";
      const [hours, minutes] = time.split(/[hm]/).map(x => parseInt(x));

      // eslint-disable-next-line
      const hoursLabel = `${new Intl.NumberFormat().format(hours)} ${t("app.hours", 2).toLowerCase()}`;
      // eslint-disable-next-line
      const minutesLabel = `${new Intl.NumberFormat().format(minutes)} ${t("app.minutes", 2).toLowerCase()}`;

      if (hours && minutes) {
        return `${hoursLabel} ${minutesLabel}`;
      }

      if (hours) {
        return hoursLabel;
      }

      if (minutes) {
        return minutesLabel;
      }

      return "-";
    });

    return {
      displayedTime
    };
  }
};
</script>
